<template>
    <div>
        <div class="tab_content">
            <el-form :model="ruleForm"  label-width="100px" class="demo-ruleForm">
                <el-form-item label="机构编号" >
                    <el-input v-model="ruleForm.orgCard"  readonly></el-input>
                </el-form-item>
                <el-form-item label="机构名称" >
                    <el-input v-model="ruleForm.orgName"  readonly></el-input>
                </el-form-item>
                <el-form-item label="法定代表人" >
                    <el-input v-model.number="ruleForm.legPerson" autocomplete="off" readonly></el-input>
                </el-form-item>
                <el-form-item label="联系电话" >
                    <el-input v-model.number="ruleForm.phone" autocomplete="off" readonly></el-input>
                </el-form-item>
                <el-form-item label="办公地址" >
                    <el-input v-model.number="ruleForm.busAddress" autocomplete="off" readonly></el-input>
                </el-form-item>
                <el-form-item label="邮箱" >
                    <el-input v-model.number="ruleForm.mail" autocomplete="off" readonly></el-input>
                </el-form-item>
                <el-form-item label="建立时间" >
                    <el-col :span="24">
                        <el-date-picker type="date"  v-model="ruleForm.estTime"
                                        value-format="yyyy-MM-dd"
                                        style="width: 100%;" readonly></el-date-picker>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-button @click.prevent="back" size="small">返回</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "qyInfo",
        data() {
            return {
                ruleForm: {}, //企业数据
                url: this.$Config.base_server
            }
        }, mounted() {
            this.common();
        }, methods: {
            common() {
                const _this = this;
                //查询当前机构
                $.get(this.url + '/api-public/opeorg/findOpeOrgByUserId/' + JSON.parse(localStorage.getItem("user")).id + "?access_token=" + localStorage.getItem("token"), function (result) {
                    _this.ruleForm = result;
                });
                console.log(JSON.stringify(_this.ruleForm));
            }, back() {
                this.$router.go(-1);
            }
        }
    }
</script>

<style scoped>
    .content {
        padding: 20px 111px;
        background: #fff;
    }

    .tab_content {
        margin-bottom: 20px;
    }

    .el-form {
        width: 600px;
        margin: 0 auto;
    }
</style>